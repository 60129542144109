export const main = {
  name: "Mahfuz",
  occupation: "Founder of Onyxium AI",
  // description: "I'm a Front-End Developer",
  image: "aboutpic.png",
  bio: "As a seasoned Full Stack Web Developer, I've delivered numerous successful projects for various clients. My expertise encompasses technologies like Next.js, Typescript, Node.js, TailwindCSS, PostgreSQL, and more. Additionally, I excel in Discord.js development and integrating APIs from RapidAPI and Google Cloud Console.",
  email: "mahfujulhuq07@gmail.com",
  quote: "Don't wait. The time will never be just right.",
  address: "Dhaka, Bangladesh",
  website: "https://onyxium.org",
  resumedownload:
    "https://drive.google.com/file/d/16AALn7MR9WaI3wgPwIWOKFY-IQLmZBOL/view?usp=share_link",
  social: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/mahfuz007",
      iconName: "logo-linkedin",
    },
    {
      name: "twitter",
      url: "https://twitter.com/KMahfujul",
      iconName: "logo-twitter",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/fuzious",
      iconName: "logo-instagram",
    },
    {
      name: "github",
      url: "https://github.com/mahfuz0001",
      iconName: "logo-github",
    },
  ],
};

export const about = {
  bio: "I am a front-end <br /><span class='text-primary'>developer</span> who cares<br /> about the mission",
  ido: "<p>Web Developement</p><p>Web Designing</p><p>Discord Bot</p><p>Backend</p>",
  years: 5,
  image: "img/perfect-min.png",
  resumedownload:
    "https://drive.google.com/file/d/16AALn7MR9WaI3wgPwIWOKFY-IQLmZBOL/view?usp=share_link",
};

export const skills = {
  title:
    "Empowering Global Impact Through <span class='text-primary'>Web Development</span>",
  subtitle:
    "Dedicated to Simplifying and Democratizing Access to the Digital Realm",
  skills: [
    {
      title: "Expert at",
      skills: [
        "Next.js",
        "TypeScript",
        "Tailwind CSS",
        "Node.js",
        "Discord.js",
      ],
    },
    {
      title: "Frontend",
      skills: [
        "JavaScript",
        "TypeScript",
        "Next.js",
        "React.js",
        "Tailwind CSS",
        "Shadcn UI",
        "Redux",
        "Material UI",
      ],
    },
    {
      title: "Backend",
      skills: ["Node.js", "Express.js", "Discord.Js", "Stripe"],
    },
    {
      title: "Database",
      skills: ["PostgreSQL", "Supabase", "MongoDB", "Firebase"],
    },

    {
      title: "Others",
      skills: ["Git", "Figma", "OpenAI"],
    },
  ],
};

export const projects = [
  {
    title: "Onyxium AI",
    category: "AI Tools",
    url: "https://onyxium.org",
  },
  {
    title: "FeedbackFlow",
    category: "Feedback Tool",
    url: "https://feedbackflow.nexonlabs.uk",
  },
  {
    title: "Nexon Labs",
    category: "Software Development",
    url: "https://nexonlabs.uk",
  },
  {
    title: "Kualify",
    category: "SASS Builder",
    url: "https://kualify.vercel.app",
  },
  {
    title: "Threads Clone",
    category: "Social Media",
    url: "https://snapout-threads.vercel.app",
  },
  {
    title: "Chatify",
    category: "Chat Application",
    url: "https://ogchatify.vercel.app",
  },
  {
    title: "Kai AI",
    category: "Website",
    url: "https://kai-ai.vercel.app/",
  },
  {
    title: "Snapout",
    category: "Social Media",
    url: "https://sumo-shots.vercel.app/",
  },
];

export const works = [
  [
    {
      company: "Onyxium AI",
      title: "Founder & CEO",
      image: "img/behance.png",
      years: "2023 - Present",
      description:
        "• Proficiency in Next.js, React.js, TypeScript, Tailwind CSS, and other tools for Front-End development. <br />• Played a pivotal role in crafting the structure, design, behavior, and content of websites, web applications, and mobile apps, ensuring seamless user experiences and functionality.",
    },
    {
      company: "Nexorise LLC",
      title: "Software Developer",
      image: "img/behance.png",
      years: "Present",
      description:
        "• Proficiency in Next.js, React.js, TypeScript, Tailwind CSS, and other tools for Front-End development. <br />• Played a pivotal role in crafting the structure, design, behavior, and content of websites, web applications, and mobile apps, ensuring seamless user experiences and functionality.",
    },
    {
      company: "Kai Inc",
      title: "Web Developer",
      image: "img/behance.png",
      years: "2023",
      description:
        "• Spearheaded the development of a robust web application for Kai Inc, catering to an extensive user base of over 45,000 individuals. <br />• Leveraged cutting-edge technologies to ensure optimal performance and user experience. <br />• Collaborated closely with the team to implement innovative features and enhancements, contributing to the platform's continuous evolution and growth.",
    },
    {
      company: "",
      title: "Discord Bot Developer",
      image: "img/behence.png",
      years: "2022 - Present",
      description:
        "• Developed multiple Discord bots and proficient in creating various types of Discord bots using Discord Js. <br />• Implemented custom commands, event handlers, and integrations to enhance bot functionality and user engagement.<br />• Stayed updated with the latest developments in Discord.js and leveraged new features to improve bot performance and capabilities.",
    },
  ],
];

// export const partners = [
//   {
//     image: "1.png",
//   },
//   {
//     image: "workslee.png",
//   },
// ];

export const testimonials = [
  {
    text: "We are very content and happy wan all the work mahfujul has done for us! Not only did he just what he had to do, comes up with suggestions and improvements of his owns and points out weaknesses which he in perfect communication is able to improve. Recommended! ",
    user: "Jason Sindram",
    company: "CTO at DP-Websolutions",
  },
  {
    text: "Superb. Great comms, understood the requirement, and delivered quickly. Multiple revisions allowed which were very helpful. I will work with him again.",
    user: "Bhavesh Patel",
    company: "CEO at Ganlaxgroup",
  },
];
